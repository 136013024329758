<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">储值卡列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editMonthCard()">添加</el-button>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="batchEditMonthCard()">批量添加</el-button>
            </div>
        </div>
        <el-card class="el-main sceneCheckContent">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="套餐名称" v-model="pageParam.params.name" />
                <le-select-remote-search label="所属项目" v-model="pageParam.params.incomeDistriId" :options="optionsincomeDistri" placeholder="请选择项目(可搜索)" />
                <le-input label="站点名称" v-model="pageParam.params.stationName" />
                <le-input label="套餐ID" v-model="pageParam.params.id" />
            </le-search-form>
            <le-pagview ref="storedValuelistPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.storedValuelist">
                <el-table ref="storedValuelist" :data="tableData" :highlight-current-row="true"  style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="id" label="ID" width="80" fixed="left"></el-table-column>
                    <el-table-column prop="name" label="所属商户" min-width="140">
                        <template slot-scope="{ row }">
                            <!-- <span class="a-c-blue font-point" @click="editMonthCard(row)">{{ row.name?row.name:'-' }}</span> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="套餐名称" min-width="140" fixed="left">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="editMonthCard(row)">{{ row.name?row.name:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="划线价" min-width="150">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.price) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="金额" min-width="150">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.promotionPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="毛豆价值" min-width="150">
                        <template slot-scope="{ row }">
                            <jd-md-price :number="row.promotionPrice"></jd-md-price>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="赠送(毛豆)" min-width="150">
                        <template slot-scope="{ row }">
                            <jd-md-price :number="row.giftAmount"></jd-md-price>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomeDistriName" label="所属项目" min-width="200"></el-table-column>
                    <el-table-column prop="name" label="适用站点" min-width="240">
                        <template slot-scope="{ row }">
                            <div class="a-flex-rfsc a-flex-wrap" v-if="row.stationList.length < 2">
                                <span class="stationTag" v-for="(item,index) in row.stationList" :key="index">{{ item.name?item.name:'-' }}</span>
                            </div>
                            <span class="font-point a-c-blue" v-else @click="showMoreStation(row.stationList)">查看更多</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTimeText" label="创建时间" min-width="170"></el-table-column>
                    <el-table-column prop="name" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="editMonthCard(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                                <el-dropdown placement="bottom-start" @command="handlerOperation">
                                    <img src="../../assets/icon/more-operation.png" class="a-wh-16" />
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item 
                                            v-for="item in optionsMoreOperation" 
                                            :command='item.value+"#"+scope.$index' 
                                            :disabled="item.value == 6 && scope.row.type == 4"
                                            :key="item.value" 
                                            :value="item.value">{{ item.label }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
        <el-dialog
            title="适用站点"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-table :data="stationList" style="width: 100%">
                    <el-table-column prop="code" label="站点ID" width='100'></el-table-column>
                    <el-table-column prop="name" label="站点名称"></el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button type="primary"  @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
        <add-stored-value ref="storedValue" @success='addSuccess'></add-stored-value>

    </div>
</template>

<script>
import util from '../../../src/utils/util'
import addStoredValue from './child/addStoredValue.vue';
    export default {
        components: { addStoredValue },
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getMonthcardPage,
                    method: "post",
                    params: {
                        name: '',
                        type: '5',
                        stationName: '',
                        id: '',//月卡id
                        incomeDistriId: '',
                        isTempCard: '6'
                    },
                    freshCtrl: 1,
                },
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList, //getIncomeStatInfoList
                    method: "post",
                    params: {
                        searchKey: "",
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                optionsMoreOperation: [{
                    label: "删除",
                    value: 1,
                }, {
                    label: "置顶",
                    value: 2,
                }, {
                    label: "上移",
                    value: 3,
                }, {
                    label: "下移",
                    value: 4,
                }],
                moreInfoLeft: '16px',
                dialogVisible: false,
                stationList: []
            }
        },
        mounted () {
 
        },
        activated () {
            this.pageParam.freshCtrl++;
        },  
        methods: {
            //获取列表
            setTableData(data) {
                // this.tableData = data;
                let arr = []
                data.map(item=>{
                    item.voList.map(ite=>{
                        arr.push({
                            groupId: item.groupId,
                            ...ite
                        })
                    })
                });
                this.tableData = arr
            },
            handlerRest() {
                this.pageParam.params = {
                    name: '',
                    type: '5',
                    stationName: '',
                    id: '',//月卡id
                    incomeDistriId: '',
                    isTempCard: '6'
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['storedValuelistPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 添加/编辑月卡
            editMonthCard (datas) {
                if(datas) {
                    this.$refs['storedValue'].form.id = datas.id
                    this.$refs['storedValue'].form.name = datas.name
                    this.$refs['storedValue'].form.incomeDistriId = datas.incomeDistriId
                    this.$refs['storedValue'].form.validity = datas.validity
                    this.$nextTick(()=>{
                        this.$refs['storedValue'].form.statioinIdList = datas.stationList.map(item=>{
                            return item.code
                        })
                    });
                    this.$refs['storedValue'].form.promotionPrice = Number((datas.promotionPrice/100).toFixed(2))
                    this.$refs['storedValue'].form.giftAmount = datas.giftAmount
                }
                
                this.$refs['storedValue'].dialogVisible = true
            },
            batchEditMonthCard () {
                this.$router.push({
                    path:'/monthCard/monthCard-storedValue-batch',
                })
            },
            handlerOperation (commands) {
                let command = commands.split('#')[0]
                let index = commands.split('#')[1]
                switch (command) {
                    case '1':
                        this.deleteMonthCard(this.tableData[index])
                        break;
                    case '2':
                        this.sortMonthCard(this.tableData[index],3)
                        break;
                    case '3':
                        this.sortMonthCard(this.tableData[index],1)
                        break;
                    case '4':
                        this.sortMonthCard(this.tableData[index],2)
                        break;
                    case '5':
                        this.suspendMonthCard(this.tableData[index])
                        break;
                    case '6':
                        this.addUserMonth(this.tableData[index])
                        break;
                    default:
                        break;
                }
            },
            // 删除卡片
            deleteMonthCard (datas) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.deleteMonthcard,
                        method: "post",
                        params: {
                            groupId: datas.groupId,
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        } else {
                            
                        }
                    })
                    .catch(err=>{
                        console.log(err);
                        if(err = 'Error: 此卡片已经管理用户请先解绑用户'){
                            this.$confirm('此卡片已经管理用户请先解绑用户','温馨提示',{
                                confirmButtonText:'去解绑'
                            })
                            .then(_=>{
                                this.$router.push({
                                    path: '/monthCard/monthCard-relation'
                                })
                            })
                        }
                        
                    })
                })
                .catch(_=>{})
            },
            // 卡片排序
            sortMonthCard (datas,type) {
                let content = type == 3 ? '是否确认置顶？' : type == 1 ? '是否确认上移？' : '是否确认下移？'
                this.$confirm(content,'温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.monthcardMove,
                        method: "post",
                        params: {
                            groupId: datas.groupId,
                            moveOperation: type
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                })
                .catch(_=>{})
            },
            // 暂停卡片
            suspendMonthCard (datas) {
                this.$confirm('是否确认暂停？','温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.stopMonthcard,
                        method: "post",
                        params: {
                            monthcardId: datas.id,
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                })
                .catch(_=>{})
            },
            showMoreStation (datas) {
                this.stationList = datas
                this.dialogVisible = true
            },
            addSuccess () {
                this.handlerSearch()
            }
        }
    }
</script>

<style lang="scss" scoped>
.monthCard{
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__expand-column .cell {
        // display: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
}
/deep/ .sceneCheckContent{
    .el-table__column-filter-trigger{
        display: none;
    }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.formContent{
    max-height: 70vh;
    overflow: auto;
}
</style>